import { React, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

export default function Counter({
  className = "",
  quantity,
  setQuantity,
  allowZero = false,
  ...props
}) {
  const handleChange = (e) => {
    // if (e.target.value === '') return
    let val = parseInt(e.target.value);
    setQuantity(val);
  };
  //increase counter
  const increase = () => {
    setQuantity((count) => count + 1);
  };

  //decrease counter
  const decrease = () => {
    if (allowZero ? quantity >= 1 : quantity >= 2) {
      setQuantity((count) => count - 1);
    }
  };

  return (
    <Form.Group className={`counter counter-border ${className}`} {...props}>
      <Button variant="light" onClick={decrease} className="border-0">
        <img src="/img/minus.svg" alt="" />
      </Button>
      <Form.Control
        value={quantity}
        size="sm"
        className="text-center"
        type={"number"}
        onChange={handleChange}
      />
      <Button variant="light" onClick={increase} className="border-0">
        <img src="/img/plus.svg" alt="" />
      </Button>
    </Form.Group>
  );
}
