import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import NavigationMain from "../components/navbars/navbarMain";
import SideBar from "../components/sidebar/sideBar";
import {
  Container,
  Row,
  Col,
  ToastContainer,
  Toast,
  Spinner,
} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { setToast } from "../redux/toastSlice";

const MainLayout = () => {
  const userData = useSelector((state) => state.user.data);

  const { show, variant, message, header, delay } = useSelector(
    (state) => state.toast
  );
  const dispatch = useDispatch();
  const [showSidebar, setShowSideBar] = useState(false);
  const sidebarToggle = () => {
    setShowSideBar((state) => !state);
  };

  if (!userData) {
    return (
      <div className="w-100 vh-100 d-flex justify-content-center align-items-center">
        <Spinner animation="border" />
      </div>
    );
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col xl="2" className={`px-0 sidebar ${showSidebar ? "open" : ""} `}>
            <Button
              variant="link"
              className={`sidebar-toggler ${showSidebar ? "open" : ""}`}
              onClick={sidebarToggle}
            >
              <div></div>
              <div></div>
              <div></div>
            </Button>
            <SideBar sidebarToggle={sidebarToggle} />
          </Col>

          <Col xl="10">
            <NavigationMain />
            <Outlet />
          </Col>
        </Row>
      </Container>
      <ToastContainer className="p-3" position={"middle-center"}>
        <Toast
          show={show}
          delay={5000}
          autohide={true}
          bg={variant}
          onClose={() => dispatch(setToast({ show: false }))}
        >
          {header !== "" ? (
            <Toast.Header closeButton={true}>
              <strong className="me-auto">{header}</strong>
            </Toast.Header>
          ) : null}

          <Toast.Body>{message}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};

export default MainLayout;
