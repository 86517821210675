import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  FormGroup,
  FormLabel,
  FormSelect,
  Row,
  Spinner,
} from "react-bootstrap";
import Table from "react-bootstrap/Table";
import axios from "axios";
import ProductCell from "../components/orderTable/productCell";
import CheckboxSelect from "../components/checkboxSelect/checkbosSelect";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import YouTube from "react-youtube";
function OrderTable() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [categories, setCategories] = useState([]);
  const [collections, setCollections] = useState(null);
  const [data, setData] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [collectionsLoading, setCollectionsLoading] = useState(false);
  const [adding, setAdding] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [filterColors, setFilterColors] = useState([]);
  const [existingStatuses, setExistingStatuses] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const categoryRef = useRef(null);
  const colorRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    getCategories();
  }, []);
  function getCategories() {
    axios.get("/category").then((res) => {
      setCategories(res.data.items);
    });
  }
  function resetSelectedProducts() {
    setSelectedProducts([]);
    setData(null);
  }
  function handleCategory(e) {
    resetSelectedProducts();
    if (e.target.value === "") {
      setCollections([]);
      return;
    }
    setCollectionsLoading(true);
    axios
      .get(`/product-form/list/${e.target.value}`)
      .then((res) => {
        setCollections(res.data.items);
      })
      .finally(() => {
        setCollectionsLoading(false);
      });
  }
  function handleStatuses(data) {
    let uniqueStatuses = [];

    data.colors?.forEach((item) => {
      // ["1"].productStatuses;
      for (const property in item.products) {
        const statusList = item.products[property]?.productStatuses;

        if (
          statusList?.length &&
          !uniqueStatuses.some(
            (element) => element.name === statusList[0]?.name
          )
        ) {
          uniqueStatuses.push(statusList[0]);
        }
      }
    });
    setExistingStatuses(uniqueStatuses);
  }
  function handleCollection(e) {
    resetSelectedProducts();
    if (e.target.value === "") {
      return;
    }
    setDataLoading(true);

    axios
      .get(`/product-form/${e.target.value}`)
      .then((res) => {
        setData(res.data);
        handleStatuses(res.data);
      })
      .finally(() => {
        setDataLoading(false);
      });
  }
  function handleSelectedProduct(
    quantity,
    selectUnit,
    productSapIndex,
    unit,
    selectedQuantity
  ) {
    setSelectedProducts((state) => {
      if (!selectedQuantity) {
        const newState = state.filter(
          (item) => item.productSapIndex !== productSapIndex
        );
        return newState;
      }

      const isOnList = state?.find(
        (item) => item.productSapIndex === productSapIndex
      );
      if (!isOnList) {
        const newState = [
          ...state,
          { productSapIndex, quantity, selectUnit, unit },
        ];
        return newState;
      }
      const newState = state.map((item) => {
        if (item.productSapIndex === productSapIndex) {
          return {
            ...item,
            quantity,
            selectUnit,
          };
        } else {
          return item;
        }
      });

      return newState;
    });
  }
  function addToCart() {
    setAdding(true);
    const data = JSON.stringify({
      products: selectedProducts,
    });

    axios
      .post("/cart/multi", data)
      .then((res) => {
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 5000);
      })
      .finally(() => {
        setAdding(false);
      });
  }

  return (
    <div style={{ padding: "15px" }}>
      <Row ref={containerRef}>
        <Col sm={3}>
          <FormGroup>
            <FormLabel>{t("OrderTable.selectCategory")}</FormLabel>
            <FormSelect
              className={` h-40 search-togglers arrow-after arrow-dropdown-right rounded-pill `}
              onChange={handleCategory}
              ref={categoryRef}
            >
              <option value={""}></option>

              {categories?.map((category, i) => (
                <option value={category.id} key={i}>
                  {category.name}
                </option>
              ))}
            </FormSelect>
          </FormGroup>
        </Col>
        <Col sm={3}>
          <FormGroup>
            <FormLabel>{t("OrderTable.selectSeries")}</FormLabel>
            {collectionsLoading ? (
              <div className="w-100 d-flex justify-content-center">
                <Spinner animation="border" size="sm" />
              </div>
            ) : (
              <FormSelect
                className={` h-40 search-togglers arrow-after arrow-dropdown-right rounded-pill `}
                onChange={handleCollection}
                disabled={categoryRef?.current?.value === ""}
                ref={colorRef}
              >
                <option value={""}></option>

                {collections?.map((collection) => (
                  <option value={collection.id}>{collection.name}</option>
                ))}
              </FormSelect>
            )}
          </FormGroup>
        </Col>
        <Col sm={3}>
          <FormGroup>
            <FormLabel>{t("OrderTable.selectColor")}</FormLabel>
            {dataLoading ? (
              <div className="w-100 d-flex justify-content-center">
                <Spinner animation="border" size="sm" />
              </div>
            ) : (
              <CheckboxSelect
                options={data?.colors?.map((item) => ({
                  label: item.name,
                  value: item.name,
                }))}
                value={filterColors}
                onChange={setFilterColors}
                disabled={colorRef?.current?.value === "" || data === null}
              />
            )}
          </FormGroup>
        </Col>
        <Col sm={3}>
          {existingStatuses.length ? (
            <FormLabel>{t("OrderTable.productStatuses")}</FormLabel>
          ) : null}
          {existingStatuses?.map((item) => (
            <div className="d-flex align-items-center">
              <div
                className="border border-black me-1"
                style={{
                  backgroundColor: `${item.color}`,
                  width: "20px",
                  height: "20px",
                }}
              ></div>
              {item.name}
            </div>
          ))}
        </Col>
      </Row>

      {
        dataLoading ? (
          <div className="w-100 pt-5 d-flex align-items-center justify-content-center">
            <Spinner animation="border" />
          </div>
        ) : data ? (
          <div
            className="order-table  mt-3"
            style={{
              height: `calc(100vh - (${containerRef?.current?.clientHeight}px + 190px))`,
            }}
          >
            <Table>
              <thead>
                <tr>
                  {data?.headers?.map((header, index) => (
                    <th key={index}>{header.name}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data?.colors
                  ?.filter((item) => {
                    if (!filterColors.length) {
                      return true;
                    }
                    return filterColors.some(
                      (element) => item.name === element
                    );
                  })
                  .map((color, i) => (
                    <tr key={i}>
                      {data?.headers?.map((item, index) => {
                        const product = color.products[item.position];

                        return product === null ? (
                          <td></td>
                        ) : item.position === 0 ? (
                          <td key={index}>{color.name}</td>
                        ) : (
                          <ProductCell
                            key={index}
                            color={color}
                            item={item}
                            handleSelectedProduct={handleSelectedProduct}
                            product={product}
                          />
                        );
                      })}
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        ) : null
        // <div className="w-100 pt-5 d-flex align-items-start justify-content-start flex-column">
        //   <h5>{t("OrderTable.instruction")}</h5>
        //   <YouTube
        //     videoId="MLpWrANjFbI"
        //     opts={{
        //       height: "390",
        //       width: "640",
        //       playerVars: {
        //         // https://developers.google.com/youtube/player_parameters
        //         autoplay: 0,
        //       },
        //     }}
        //   />
        // </div>
      }
      <div className="w-100 d-flex justify-content-end align-items-center mt-3 pe-3 ">
        <Alert
          variant="success"
          className={`me-3 mb-0 ${showAlert ? "visible" : "invisible"}`}
        >
          {t("Orders.cart_added")}
        </Alert>
        {data ? (
          <Button
            disabled={!selectedProducts.length || adding}
            onClick={addToCart}
            variant="success"
            className=" fw-bold"
          >
            {adding ? <Spinner animation="border" size="sm" /> : t("Cart.add")}
          </Button>
        ) : null}
      </div>
    </div>
  );
}

export default OrderTable;
